
import { defineComponent } from "vue";
export default defineComponent({
  mounted() {
    if (this.eventName) {
      this.$emit("enable-next");
    }
  },
  computed: {
    eventName: {
      get() {
        return this.$store.state.eventWizardContext.eventName;
      },
      set(value: string) {
        this.$store.commit("setEventWizardNameContext", value);
      },
    },
    includeDrinks: {
      get() {
        return this.$store.state.eventWizardContext.includeDrinks;
      },
      set(value: boolean) {
        this.$store.commit("setEventWizardIncludeDrinksContext", value);
      },
    },
    includeFood: {
      get() {
        return this.$store.state.eventWizardContext.includeFood;
      },
      set(value: boolean) {
        this.$store.commit("setEventWizardIncludeFoodContext", value);
      },
    },
    includeEvents: {
      get() {
        return this.$store.state.eventWizardContext.includeEvents;
      },
      set(value: boolean) {
        this.$store.commit("setEventWizardIncludeEventsContext", value);
      },
    },
  },
  watch: {
    eventName(newName, oldName) {
      if (
        (this.includeDrinks || this.includeFood || this.includeEvents) &&
        this.eventName
      ) {
        this.$emit("enable-next");
      } else {
        this.$emit("disable-next");
      }
    },
    includeDrinks(newIncludeDrinks, oldIncludeDrinks) {
      if (
        (newIncludeDrinks || this.includeFood || this.includeEvents) &&
        this.eventName
      ) {
        this.$emit("enable-next");
      } else {
        this.$emit("disable-next");
      }
    },
    includeFood(newIncludeFood, oldIncludeFood) {
      if (
        (newIncludeFood || this.includeDrinks || this.includeEvents) &&
        this.eventName
      ) {
        this.$emit("enable-next");
      } else {
        this.$emit("disable-next");
      }
    },
    includeEvents(newIncludeEvents, oldIncludeEvents) {
      if (
        (newIncludeEvents || this.includeDrinks || this.includeFood) &&
        this.eventName
      ) {
        this.$emit("enable-next");
      } else {
        this.$emit("disable-next");
      }
    },
  },
});
